.hero-slide-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(130,160,150,0.3)
}

.music-btn{
  position: fixed;
  bottom: 10px;
  right: 20px;
  cursor: pointer;
  color: white;
  background: #b4d2c8;
  border-color: #b4d2c8;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  z-index: 100;
}

.music-btn:hover{
  background: linear-gradient(0.2turn,#b4d2c8,#dcc8b4);
}

.music-active{
  background: linear-gradient(0.5turn,#b4d2c8,#dcc8b4);
  filter: drop-shadow(2mm 1mm 2mm #aeebd7);
}

.music-btn > svg{
  display: block;
  margin: auto;
  font-size: 20px;
  height: inherit;
}

.slide-image{
  height: 891px;
  object-fit: cover;
}

.slide-section{
  position: relative;
}

.scroll-animation{
  position: absolute;
  bottom: 38px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
  flex-direction: column;
}

.scroll-anim-content{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  gap:5px;
}

.scroll-anim-content>span{
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: Georgia,Times,"Times New Roman",serif;
}

.scroll-anim-content>svg{
  font-size: 20px;
}

.Navbar{
  height: 71px;
}

.Navbar .nav-content{
  position: absolute;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 71px;
  border-bottom: 1px solid;
  background-color: rgba(82, 161, 135, 0.82);
  border-color: #e5e5e5;
}

.Navbar .nav-content .wrapper{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.Navbar .nav-content .wrapper .links{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.Navbar .nav-content .wrapper .links .links-item{
  text-decoration: none;
  font-family: Georgia,Times,"Times New Roman",serif;
  font-style: italic;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 0px 4px 22px 4px;
}

.Navbar .nav-content .wrapper .links .links-item:hover{
  color: rgb(62, 62, 62);
  border-bottom: 4px solid #b4d2c8;
}

.Navbar .nav-content .wrapper .ribbon .ribbon-menu{
  position: relative;
  background: #b4d2c8;
  text-decoration: none;
  padding: 30px 15px 35px 15px;
  transition: 0.5s ease-in-out;
}

.Navbar .nav-content .wrapper .ribbon .ribbon-menu:hover{
  padding-bottom: 40px;
}

.ribbon .ribbon-menu::after{
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100%;
  height: 15px;
  border-left: 35px solid #b4d2c8;
  border-right: 35px solid #b4d2c8;
  border-bottom: 15px solid ;
  border-bottom-color: transparent;
}

.Navbar .nav-content .wrapper .ribbon .ribbon-menu>span{
  color: white;
  font-family: Georgia,Times,"Times New Roman",serif;
  font-style: italic;
}

.Navbar .nav-content .wrapper .mobile-toggle{
  display: none;
  width: 75%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
  font-size: 35px;
  color: white;
  cursor: pointer;
  transition: 1s ease-in-out;
}

.Navbar .nav-content .wrapper .mobile{
  display: flex !important;
  position: absolute;
  top: 70px;
  /* left: 25px; */
  background-color: rgb(82, 161, 135);
  width: 100%;
  flex-direction: column;
}

.Navbar .nav-content .wrapper .mobile>div{
  border-bottom: 1px solid rgb(127, 192, 171);
  width: 100%;
  text-align: center;
  padding: 5px;
}

.Navbar .nav-content .wrapper .mobile>div>a:hover{
  border-bottom: 0px !important;
}

.welcome-section{
  padding-top: 80px;
}

.welcome-section .welcome-heading{
  padding: 0px 0px 10px 0px;
}

.welcome-section .welcome-heading>h2{
  text-align: center;
  font-family: "Alex Brush";
  font-size: 400%;
  color: #c89b6e;
}

.welcome-section .welcome-heading>p{
  font-family: Georgia,Times,"Times New Roman",serif;
  color: #888888;
  font-size: 130%;
  text-align: center;
  font-style: italic;
}

.welcome-section .couple{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  width: 70%;
  margin-bottom: 30px;
}

.welcome-section .couple>div>h5{
  font-family: Georgia,Times,"Times New Roman",serif;
  font-size: 120%;
  font-style: italic;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 1em;
  color: #888;
}

.welcome-section .footer>h4{
  font-family: "Alex Brush";
  font-size: 350%;
  text-align: center;
  color: #ddb68f;
}

.welcome-section .footer>h5{
  color: #888888;
  font-size: 130%;
  font-style: italic;
  text-align: center;
  font-family: 'Georgia,Times,"Times New Roman",serif'
}

.timer-section{
  background-color: #ecf2f0;
  font-family: Georgia,Times,"Times New Roman",serif;
}

.timer-section .timer-wrap{
  padding: 60px 0px;
  display: block;
  margin: auto;
  width:0%;
}

.timer{
  display: flex;
  flex-direction: row;
  gap:100px;
  align-items: center;
  justify-content: space-around;
}

.timer .tick{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  color:#888;
  width:70px;
}

.timer .tick .tick-number{
  font-size: 400%;
  font-style: italic;
  line-height: 1;
}

.timer .tick .tick-name{
  font-size: 100%;
  font-style: italic;
  line-height: 1;
}

.gandb-section{
  padding-top: 20px;
  padding-bottom: 80px;
}

.gandb-section .gandb-heading{
  padding: 0px 0px 20px 0px;
}

.gandb-section .gandb-heading>h2{
  text-align: center;
  font-family: "Alex Brush";
  font-size: 380%;
  color: #d0aa85;
}

.gandb-section .gandb-container{
  background-color: #f6f4f2;
}

.gandb-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.gandb-container .gandb-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gandb-container .reverse{
  flex-direction: row-reverse;
}
.gandb-row>div{
  width: 50%;
}
.gandb-row .image>img{
  width: 95%;
  height: 100%;
}
.gandb-row .desc{
  text-align: left;
  padding: 0px 25px 0px 20px;
}
.gandb-row .desc>h4{
  font-family: Georgia,Times,"Times New Roman",serif;
  font-size: 120%;
  font-style: italic;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: normal;
  color: #444;
  margin-bottom: 20px;
}
.gandb-row .desc > p{
  font-family: Georgia,Times,"Times New Roman",serif;
  color: #888;
  font-size: 14px;
  line-height: 1.85;
}
.gandb-row .desc > p > div {
  height: 20px !important;
  font-size: 13px !important;
  color: #0d6efd;
  cursor: pointer;
}
.where-section{
  background-color: #f6f4f2;
}
.where-section .waw-heading{
  text-align: center;
  font-family: "Alex Brush";
  font-size: 350%;
  color: #b6885b;
}
.waw-container{
  margin-top: 40px;
}
.waw-container .waw-col{
  display: flex;
  align-items: center;
  justify-content: center;
}
.waw-container .waw-col .reg-card-image-section{
  position: relative;
}
.reg-card-image-section .reg-img-icon{
  position: absolute;
  bottom: -25px;
  width: 100%;
  text-align: center;
}
.reg-card-image-section .reg-img-icon>svg{
  font-size: 55px;
  background: #dcc8b4;
  color: white;
  border-radius: 50%;
  padding: 10px;
}
.waw-container .waw-col .reg-card-title{
  font-family: Georgia,Times,"Times New Roman",serif;
  color: #444;
  font-size: 120%;
  font-style: italic;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: 0.1em;
  text-decoration: none;
  text-transform: uppercase;
}
.waw-container .waw-col .reg-card-desc{
  color: #888;
  font-family: Georgia,Times,"Times New Roman",serif;
  font-size: 14px;
  line-height: 1.85;
}
.waw-container .waw-col .reg-card-venue{
  font-family: Georgia,Times,"Times New Roman",serif;
  color: #444;
  font-size: 105%;
  font-style: italic;
}

.where-section .party-desc{
  font-family: Georgia,Times,"Times New Roman",serif;
  color: #888;
  margin-top: 25px;
  margin-bottom: 50px;
}
.where-section .party-desc>ul>li>p{
  font-weight: bold;
}
.where-section .party-desc>ul>li>ul{
  list-style: disc;
}
.where-section .party-desc>ul{
  margin: 0 0 0.5em 5em;
  padding: 0;
}

/* Tablets */
@media only screen and (max-width:768px){
  .slide-image{
    height: 815px;
    object-fit: cover;
  }
  .Navbar .nav-content .wrapper .mobile-toggle{
    display: flex;
  }
  .timer .tick{
    gap: 8px;
    width: 25px;
  }
  .timer .tick .tick-number{
    font-size: 270%;
  }
  .timer .tick .tick-name{
    font-size: 95%;
  }
  .gandb-container .gandb-row{
    flex-direction: column;
  }
  .gandb-row>div {
    width: 75%;
  }
  .gandb-row .desc {
    padding: 25px 25px 0px 0px;
  }
}

/* Mobile */
@media only screen and (max-width:425px){
  .slide-image{
    height: 720px;
    object-fit: cover;
  }
  .welcome-section .couple{
    flex-direction: column;
    gap: 25px;
  }
  .welcome-section .welcome-heading>h2{
    font-size: 325%;
  }
  .welcome-section .welcome-heading>p{
    font-size: 110%;
  }
  .welcome-section .footer>h4{
    font-size: 315%;
  }
  .timer-section .timer-wrap{
    padding: 45px 0px;
  }
  .timer{
    gap: 25px;
  }
  .timer .tick{
    gap: 7px;
    width: 35px;
  }
  .timer .tick .tick-number{
    font-size: 180%;
  }
  .timer .tick .tick-name{
    font-size: 70%;
  }
  .Navbar .nav-content .wrapper .mobile-toggle{
    width:55%;
  }
  .where-section .party-desc>ul{
    margin: 0 0 0.5em 3em;
    padding: 10px;
  }
}

/* Laptop 1024 */
@media only screen and (max-width:1024px){
  .Navbar .nav-content .wrapper .links .links-item{
    font-size: 13px;
  }
  .gandb-row .image>img {
    width: 98%;
}
}